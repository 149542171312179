import { Promotion } from '@app/shared/models/promotion';
import { createReducer, on } from '@ngrx/store';
import { SendActions } from '../actions';

export const sendFeatureKey = 'send';

export interface State {
  error: string;
  promotionLoading: boolean;
  promotionLoaded: Promotion;
}

export const initialState: State = {
  error: null,
  promotionLoading: false,
  promotionLoaded: null,
};

export const reducer = createReducer(
  initialState,
  on(SendActions.loadPromotionsInternal, (state) => ({
    ...state,
    promotionLoading: true
  })),

  on(SendActions.loadPromotionsSuccess, (state, { promotions, language }) => {
    // filter on current user language

    const filteredPromotions = language === '*' ? promotions : promotions.filter(p => p.language === language || p.language === 'all_language');
    return {
      ...state,
      promotionLoaded: filteredPromotions[Math.floor(Math.random() * filteredPromotions.length)],
      promotionLoading: true
    };
  }),
  on(SendActions.loadPromotionsFailure, (state, { error }) => ({
    ...state,
    error,
    promotionsLoading: false
  })),
  on(SendActions.loadPromotionSuccess, (state, { promotion }) => ({
    ...state,
    promotionLoaded: promotion,
    promotionLoading: false
  })),
  on(SendActions.loadPromotionFailure, (state, { error }) => ({
    ...state,
    error,
    promotionLoading: false
  })),
);

export const getErrorMessage = (state: State) => state?.error;
export const getPromotionLoaded = (state: State) => state?.promotionLoaded;
export const getPromotionLoading = (state: State) => state?.promotionLoading;

