import { Constant } from 'src/constant';
import { ListCountriesOutput } from '@smash-sdk/vat/10-2019/types/ListCountries/ListCountries';

export function computeVatFromPriceInclTax(priceInclTax, percent) {
    return round(priceInclTax - computeExclTax(priceInclTax, percent));
}

export function computeVatFromPriceExclTax(priceExclTax, percent) {
    return round(priceExclTax / 100 * percent);
}

export function computeExclTax(priceInclTax, percent) {
    return round(priceInclTax * 100 / (100 + percent));
}

export function computeInclTax(priceExclTax, percent) {
    return round((priceExclTax / 100 * percent) + priceExclTax);
}

export function round(value, precision = 2) {
    const m = Math.pow(10, precision);
    value *= m;
    if (isNaN(value)) {
        throw new Error('Value must be an integer');
    }
    const sign = Math.sign(value);
    const isHalf = value % 1 === 0.5 * sign;
    const f = Math.floor(value);
    if (isHalf) {
        if (sign > 0) {
            value = f + sign;
        }
    }
    return (isHalf ? value : Math.round(value)) / m;
}

export function computePriceInclTax({ countryCode, priceExclTax }, country, vatNumber) {
    const info = { countryCode, vatNumber };
    if (country.eu === false) {
        return ({
            ...info,
            percent: 0,
            priceExclTax,
            priceInclTax: priceExclTax,
            priceDiff: 0,
            vat: 0,
        });
    } else if (Constant.countryCodesVAT.indexOf(country.countryCode) > - 1) {
        return ({
            ...info,
            percent: country.percent,
            priceExclTax,
            priceInclTax: computeInclTax(priceExclTax, country.percent),
            priceDiff: round(computeInclTax(priceExclTax, country.percent) - priceExclTax),
            vat: computeVatFromPriceExclTax(priceExclTax, country.percent),
        });
    } else if (vatNumber) {
        return ({
            ...info,
            percent: 0,
            priceExclTax,
            priceInclTax: priceExclTax,
            priceDiff: 0,
            vat: 0,
        });
    } else {
        if (!priceExclTax || !country.percent) {
            throw new Error('priceExclTax and/or country.percent is missing');
        }
        return ({
            ...info,
            percent: country.percent,
            priceExclTax,
            priceInclTax: computeInclTax(priceExclTax, country.percent),
            priceDiff: round(computeInclTax(priceExclTax, country.percent) - priceExclTax),
            vat: computeVatFromPriceExclTax(priceExclTax, country.percent),
        });
    }
}

export function resolveCountries(countries: ListCountriesOutput['countries']): ListCountriesOutput['countries'] {
    return countries
        .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        .map(country => {
            if (country.related) {
                const related = countries.find(data => data.countryCode === country.related);
                return {
                    ...country,
                    eu: related.eu,
                    percent: related.percent,
                    currency: Constant.Currencies[country.currency] ? country.currency : Constant.Currencies.usd.iso3,
                };
            } else {
                return { ...country, currency: Constant.Currencies[country.currency] ? country.currency : Constant.Currencies.usd.iso3, }
            }
        });
}
