import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID, Renderer2, StateKey, TransferState, makeStateKey } from '@angular/core';

import { hostnameIsFromMainDomain } from '@app/shared/helpers/location';
import { Store } from '@ngrx/store';
import { Request } from 'express';
import moment from 'moment';
import { REQUEST } from '../../../express.tokens';

import * as fromUploaderDesktop from '@app/client/uploader/store/reducers';
import { SEOService } from '@app/core/services/seo.service';
import * as fromRoot from '@app/reducers';
import * as fromUploader from '@app/client/uploader/store/reducers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subscription } from 'rxjs';

const HOMEPAGE_SHOW: StateKey<boolean> = makeStateKey('homepageShow');

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit, OnDestroy {
  yearnow: number;
  show: boolean = true;

  displayPaths = /^\/$|^\/(fr|en|de|es|it|pt)$/;
  isDesktop: boolean;
  step$: Observable<string>;
  stepDesktop$: Observable<string>;
  selectedLanguage: string;
  supportedLanguages: { id: string, name: string }[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private state: TransferState,
    private deviceDetector: DeviceDetectorService,
    private readonly store$: Store<fromUploaderDesktop.State & fromRoot.State>,
    private renderer2: Renderer2,
    private seoService: SEOService,
    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.yearnow = moment().year();

    // here we use the stateMetaKey to get what the server decided on first request
    if (isPlatformBrowser(this.platformId)) {
      this.show = this.state.get(HOMEPAGE_SHOW, false); // this is to keep in sync with the server
      this.isDesktop = this.deviceDetector.isDesktop();
      if (this.show) {
        this.seoService.setFaqRichResults(this.renderer2)
      }
    }

    this.stepDesktop$ = this.store$.select(fromUploaderDesktop.getStep);
    this.step$ = this.store$.select(fromUploader.getStep);

    const routePath = isPlatformServer(this.platformId) ? this.req.path : location.pathname;
    const isMainDomain = isPlatformServer(this.platformId) ? hostnameIsFromMainDomain(`${this.req.headers['x-forwarded-host']}`) : hostnameIsFromMainDomain(location.hostname)
    if (isMainDomain && this.displayPaths.test(routePath)) {
      this.show = true;
      this.state.set(HOMEPAGE_SHOW, <any>true);
    } else {
      this.show = false;
    }

  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
