<div class="homepage-container" *ngIf="show && ((isDesktop && (stepDesktop$ | async) === 'LANDING') || (!isDesktop && (step$ | async) === 'LANDING'))">
    <div class="homepage-placeholder">
        <header>
            <h1 [innerHtml]="'HOMEPAGE_HEADER_H1' | translate | safeHtml"></h1>
            <p [innerHtml]="'HOMEPAGE_HEADER_H2' | translate | safeHtml"></p>
        </header>
        <main>
            <section>
                <h2 [innerHtml]="'HOMEPAGE_SECTION_1_TITLE' | translate | safeHtml"></h2>
                <div class="section-container">
                    <img loading="lazy" src="/assets/img/discover/send_large_files_with_smash_best_file_transfer_service.webp " alt="{{'HOMEPAGE_SECTION_1_ALT_1' | translate}}" />
                    <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_1_TEXT' | translate | safeHtml"></div>
                    <ol>
                        <li>
                            <h3 class="ordered-list-summary" [innerHtml]="'HOMEPAGE_SECTION_1_ITEM_1' | translate | safeHtml"></h3>
                            <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_1_ITEM_1_DESCRIPTION' | translate | safeHtml"></div>
                        </li>
                        <li>
                            <h3 class="ordered-list-summary" [innerHtml]="'HOMEPAGE_SECTION_1_ITEM_2' | translate | safeHtml"></h3>
                            <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_1_ITEM_2_DESCRIPTION' | translate | safeHtml"></div>
                        </li>
                        <li>
                            <h3 class="ordered-list-summary" [innerHtml]="'HOMEPAGE_SECTION_1_ITEM_3' | translate | safeHtml"></h3>
                            <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_1_ITEM_3_DESCRIPTION' | translate | safeHtml"></div>
                        </li>
                    </ol>
                    <q class="right" [innerHtml]="'HOMEPAGE_SECTION_1_QUOTE' | translate | safeHtml"></q>
                </div>            
            </section>
            <section>
                <h2 [innerHtml]="'HOMEPAGE_SECTION_2_TITLE' | translate | safeHtml"></h2>
                <div class="section-container">
                    <img loading="lazy" src="/assets/img/discover/send_large_files_for_free_with_smash_best_file_transfer_service.webp" alt="{{'HOMEPAGE_SECTION_2_ALT_1' | translate }}" />
                    <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_2_TEXT' | translate | safeHtml"></div>
                    <ol>
                        <li>
                            <h3 class="ordered-list-summary" [innerHtml]="'HOMEPAGE_SECTION_2_ITEM_1' | translate | safeHtml"></h3>
                            <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_2_ITEM_1_DESCRIPTION' | translate | safeHtml"></div>
                        </li>
                        <li>
                            <h3 class="ordered-list-summary" [innerHtml]="'HOMEPAGE_SECTION_2_ITEM_2' | translate | safeHtml"></h3>
                            <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_2_ITEM_2_DESCRIPTION' | translate | safeHtml"></div>
                        </li>
                        <li>
                            <h3 class="ordered-list-summary" [innerHtml]="'HOMEPAGE_SECTION_2_ITEM_3' | translate | safeHtml"></h3>
                            <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_2_ITEM_3_DESCRIPTION' | translate | safeHtml"></div>
                        </li>
                    </ol>
                    <q class="left" [innerHtml]="'HOMEPAGE_SECTION_2_QUOTE' | translate | safeHtml"></q>
                </div>                
            </section>
            <section>
                <h2 [innerHtml]="'HOMEPAGE_SECTION_3_TITLE' | translate | safeHtml"></h2>
                <div class="section-container">
                    <img loading="lazy" src="/assets/img/discover/send_large_files_for_free_10GB_with_smash_best_file_transfer_service.webp" alt="{{'HOMEPAGE_SECTION_3_ALT_1' | translate}}" />
                    <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_3_TEXT' | translate | safeHtml"></div>
                    <q class="right" [innerHtml]="'HOMEPAGE_SECTION_3_QUOTE' | translate | safeHtml"></q>
                </div>                
            </section>
            <section>
                <h2 [innerHtml]="'HOMEPAGE_SECTION_4_TITLE' | translate | safeHtml"></h2>
                <div class="section-container">
                    <img loading="lazy" src="/assets/img/discover/share_large_files_with_smash_best_file_transfer_service.webp" alt="{{'HOMEPAGE_SECTION_4_ALT_1' | translate}}" />
                    <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_4_TEXT' | translate | safeHtml"></div>
                    <q class="left" [innerHtml]="'HOMEPAGE_SECTION_4_QUOTE' | translate | safeHtml"></q>    
                </div>
            </section>
            <!-- LAST SECTION ACCORDEON -->
            <section>
                <h5 [innerHtml]="'HOMEPAGE_SECTION_HOMEPAGE_ACCORDEON_TITLE' | translate | safeHtml"></h5>
                <div class="justify" [innerHtml]="'HOMEPAGE_SECTION_HOMEPAGE_ACCORDEON_SUBTITLE' | translate | safeHtml"></div>
                <!-- ACCORDEON HERE -->
                <div class="wrapper-accordeon">
                    <div>
                        <details>
                            <summary>
                                <div class="summary" [innerHtml]="'HOMEPAGE_ACCORDEON_SUMMARY_1' | translate | safeHtml"></div>
                                <div class="chevron">
                                    <span class="chev"></span>
                                </div>
                            </summary>
                        </details>
                        <div class="animate">
                            <p class="content" [innerHtml]="'HOMEPAGE_ACCORDEON_RESPONSE_1' | translate | safeHtml"></p>
                        </div>
                    </div>
                    <div>
                        <details>
                            <summary>
                                <div class="summary" [innerHtml]="'HOMEPAGE_ACCORDEON_SUMMARY_2' | translate | safeHtml"></div>
                                <div class="chevron">
                                    <span class="chev"></span>
                                </div>
                            </summary>
                        </details>
                        <div class="animate">
                            <p class="content" [innerHtml]="'HOMEPAGE_ACCORDEON_RESPONSE_2' | translate | safeHtml"></p>
                        </div>
                    </div>
                    <div>
                        <details>
                            <summary>
                                <div class="summary" [innerHtml]="'HOMEPAGE_ACCORDEON_SUMMARY_3' | translate | safeHtml"></div>
                                <div class="chevron">
                                    <span class="chev"></span>
                                </div>
                            </summary>
                        </details>
                        <div class="animate">
                            <p class="content" [innerHtml]="'HOMEPAGE_ACCORDEON_RESPONSE_3' | translate | safeHtml"></p>
                        </div>
                    </div>
                    <div>
                        <details>
                            <summary>
                                <div class="summary" [innerHtml]="'HOMEPAGE_ACCORDEON_SUMMARY_4' | translate | safeHtml"></div>
                                <div class="chevron">
                                    <span class="chev"></span>
                                </div>
                            </summary>
                        </details>
                        <div class="animate">
                            <p class="content" [innerHtml]="'HOMEPAGE_ACCORDEON_RESPONSE_4' | translate | safeHtml"></p>
                        </div>
                    </div>
                    <div>
                        <details>
                            <summary>
                                <div class="summary" [innerHtml]="'HOMEPAGE_ACCORDEON_SUMMARY_5' | translate | safeHtml"></div>
                                <div class="chevron">
                                    <span class="chev"></span>
                                </div>
                            </summary>
                        </details>
                        <div class="animate">
                            <p class="content" [innerHtml]="'HOMEPAGE_ACCORDEON_RESPONSE_5' | translate | safeHtml"></p>
                        </div>
                    </div>
                    <div>
                        <details>
                            <summary>
                                <div class="summary" [innerHtml]="'HOMEPAGE_ACCORDEON_SUMMARY_6' | translate | safeHtml"></div>
                                <div class="chevron">
                                    <span class="chev"></span>
                                </div>
                            </summary>
                        </details>
                        <div class="animate">
                            <p class="content" [innerHtml]="'HOMEPAGE_ACCORDEON_RESPONSE_6' | translate | safeHtml"></p>
                        </div>
                    </div>
                    <div>
                        <details>
                            <summary>
                                <div class="summary" [innerHtml]="'HOMEPAGE_ACCORDEON_SUMMARY_7' | translate | safeHtml"></div>
                                <div class="chevron">
                                    <span class="chev"></span>
                                </div>
                            </summary>
                        </details>
                        <div class="animate">
                            <p class="content" [innerHtml]="'HOMEPAGE_ACCORDEON_RESPONSE_7' | translate | safeHtml"></p>
                        </div>
                    </div>
                </div>

            </section>
        </main>
    </div>
    <div class="footer-placeholder">
        <footer>
            <p [innerHtml]="'HOMEPAGE_FOOTER_TITLE' | translate | safeHtml" class="footer-main-title"></p>
            <div class="footer-content">
                <div class="bloc footer-col">
                    <p [innerHtml]="'HOMEPAGE_FOOTER_COL_1_TITLE' | translate | safeHtml" class="footer-subtitle"></p>
                    <ul class="footer-list">
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_1_ITEM_1_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_1_ITEM_1' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_1_ITEM_2_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_1_ITEM_2' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_1_ITEM_3_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_1_ITEM_3' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_1_ITEM_4_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_1_ITEM_4' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_1_ITEM_5_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_1_ITEM_5' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_1_ITEM_6_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_1_ITEM_6' | translate | safeHtml"></a></li>
                        <li><a target="_blank" href="{{ 'HOMEPAGE_FOOTER_COL_1_ITEM_7_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_1_ITEM_7' | translate | safeHtml"></a></li>
                    </ul>
                </div>
                <div class="bloc footer-col">
                    <p [innerHtml]="'HOMEPAGE_FOOTER_COL_2_TITLE' | translate | safeHtml" class="footer-subtitle"></p>
                    <ul class="footer-list">
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_2_ITEM_1_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_2_ITEM_1' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_2_ITEM_2_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_2_ITEM_2' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_2_ITEM_3_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_2_ITEM_3' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_2_ITEM_4_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_2_ITEM_4' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_2_ITEM_5_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_2_ITEM_5' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_2_ITEM_6_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_2_ITEM_6' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_2_ITEM_7_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_2_ITEM_7' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_2_ITEM_8_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_2_ITEM_8' | translate | safeHtml"></a></li>
                    </ul>
                </div>
                <div class="bloc footer-col">
                    <p [innerHtml]="'HOMEPAGE_FOOTER_COL_3_TITLE' | translate | safeHtml" class="footer-subtitle"></p>
                    <ul class="footer-list">
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_3_ITEM_1_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_3_ITEM_1' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_3_ITEM_2_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_3_ITEM_2' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_3_ITEM_3_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_3_ITEM_3' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_3_ITEM_4_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_3_ITEM_4' | translate | safeHtml"></a></li>
                    </ul>
                </div>
                <div class="bloc footer-col">
                    <p [innerHtml]="'HOMEPAGE_FOOTER_COL_4_TITLE' | translate | safeHtml" class="footer-subtitle"></p>
                    <ul class="footer-list">
                        <li><a target="_blank" href="{{ 'HOMEPAGE_FOOTER_COL_4_ITEM_1_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_4_ITEM_1' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_4_ITEM_2_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_4_ITEM_2' | translate | safeHtml"></a></li>
                        <li><a href="{{ 'HOMEPAGE_FOOTER_COL_4_ITEM_3_LINK' | translate}}"
                                [innerHtml]="'HOMEPAGE_FOOTER_COL_4_ITEM_3' | translate | safeHtml"></a></li>
                    </ul>
                </div>
                <div class="bloc footer-col">
                    <div class="store">
                        <a target="_blank" href="{{ 'HOMEPAGE_FOOTER_APP_STORE_LINK' | translate }}"><img loading="lazy"
                                src="/assets/img/appstore.png" alt=""></a>
                        <a target="_blank" href="{{ 'HOMEPAGE_FOOTER_GOOGLE_STORE_LINK' | translate }}"><img loading="lazy"
                                src="/assets/img/google-play.png" alt=""></a>
                    </div>
                    <hr>
                    <div>
                        <a target="_blank" class="social" href="{{ 'HOMEPAGE_FOOTER_LINKEDIN_LINK' | translate }}"><i
                                class="icon-smash_linkedin2"></i></a>
                        <a target="_blank" class="social" href="{{ 'HOMEPAGE_FOOTER_FACEBOOK_LINK' | translate }}"><i
                                class="icon-smash_facebook"></i></a>
                        <a target="_blank" class="social" href="{{ 'HOMEPAGE_FOOTER_TWITTER_LINK' | translate }}"><i
                                class="icon-smash_twitter"></i></a>
                        <a target="_blank" class="social" href="{{ 'HOMEPAGE_FOOTER_INSTAGRAM_LINK' | translate }}"><i
                                class="icon-smash_instagram"></i></a>
                        <a target="_blank" class="social" href="{{ 'HOMEPAGE_FOOTER_YOUTUBE_LINK' | translate }}"><i
                                class="icon-smash_youtube"></i></a>
                    </div>
                </div>
            </div>
            <hr>
            <div class="footer-copyright-language">
                <p class="copyright">Smash & Co © 2017-{{yearnow}}</p>
                <div class="select-lang-container">
                    <app-smash-select-lang dropdownDirection="up" chevronColor="#FFF"></app-smash-select-lang>
                </div>
            </div>
        </footer>
    </div>

</div>