import { Constant } from 'src/constant';
import { environment } from 'src/environments/environment';
import { Language } from '@app/shared/types/Language';

const regexpSmashDomain = /fromsmash.com|fromsmash.ninja/;
/* Retrieve domain without subdomain  */
export function getDomainWithoutSubDomain(url: string): string {
    const urlParts = url.split('.');
    return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.');
}

/* Used to retrieve main domain + port (if exists). */
/* not used anymore */
export function getProtocolMainDomainAndPort() {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    return protocol + '//' + getDomainWithoutSubDomain(hostname) + (!!port ? ':' + port : '');
}

/* Used to retrieve domain + port (if exists). */
export function getProtocolHostnameAndPort() {
    const url = window.location.href;
    const arr = url.split('/');
    return arr[0] + '//' + arr[2];
}

export function hostnameIsFromMainDomain(url: string) {
    const regExp = new RegExp(`^(www\.)?${environment.appDomain}`);
    return regExp.test(url);
}

export function formatMainDomain() {
    if (typeof window !== 'undefined') {
        const { protocol, hostname, port } = window.location;
        const customCNAME = window.location.hostname.match(regexpSmashDomain) ? false : true;
        if (customCNAME) {
            return `${protocol}//${hostname}${port ? ':' + port : ''}`;
        } else {
            const [, domain] = hostname.match(Constant.domainRegexp);
            return `${protocol}//${domain}${port ? ':' + port : ''}`;
        }
    }
}

export function isHostnameCNAME() {
    return regexpSmashDomain.test(window.location.hostname) ? false : true;
}

export function extractDomainSuffixOrCNAME() {
    if (typeof window !== 'undefined') {
        const { hostname } = window.location;
        if (isHostnameCNAME()) {
            return hostname;
        }
        const [, domain] = hostname.match(Constant.domainRegexp);
        return `.${domain}`;
    }
}

export function getMainDomainSuffix() {
    if (typeof window !== 'undefined') {
        const { protocol, hostname, port } = window.location;
        const [, domain] = hostname.match(Constant.domainRegexp);
        return `.${domain}`;
    }
}

export function getMainDomainSuffixServerSide(host?: string) {
    const regexResult = host.match(Constant.domainRegexp);
    if (regexResult && regexResult.length) {
        const [, domain] = regexResult;
        return `.${domain}`;
    }
}

export function getPathname() {
    if (typeof window !== 'undefined') {
        return window.location.pathname;
    }
}

export function getPathWithoutLastFragment(): string {
    const splittedPath = getPathname().split('/');
    const pathWithoutLastFragment = splittedPath.slice(0, splittedPath.length - 1).join('/');
    return pathWithoutLastFragment;
}

export function getCurrentPath(url: string) {
    let currPath;
    const pageWithSeoREgexExp2 = /\/(fr|de|pt|it|es)?\/?([|signin|signup\/plan?\/[a-zA-Z0-9\s%]+)/;
    const matchPath = url.match(pageWithSeoREgexExp2);
    const regexLang = /^en|fr|de|pt|it|es$/;
    if (matchPath[1]) {
        const [isLang] = matchPath[1].match(regexLang);
        if (isLang && matchPath[2]) {
            currPath = matchPath[2];
        } else {
            currPath = '';
        }
    } else if (matchPath[2]) {
        currPath = matchPath[2];
    } else {
        currPath = '';
    }
    return currPath;
}

export function getCurrentLangFromPath(url: string): Language {
    if (url.split('/')[1] && url.split('/')[1].length !== 2) {
        return 'en';
    }
    const pageWithSeoREgexExp = /\/(fr|de|de|pt|it|es)?\/?(|signin|signup)?/;
    const matchPath = url.match(pageWithSeoREgexExp);
    return matchPath[1] ? matchPath[1] as Language : 'en';
}


export function redirectToAccount(teamId: string) {
    // if hostname is customized redirect to same hostname
    const customCNAME = window.location.hostname.match(regexpSmashDomain) ? false : true;
    if (customCNAME) {
        window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/account/team/${teamId}/transfers/sent`;
    } else {
        const [, ext] = window.location.hostname.match(Constant.regexpDomainExtension);
        window.location.href = `${window.location.protocol}//${Constant.redirection.main}${ext}${window.location.port ? ':' + window.location.port : ''}/account/team/${teamId}/transfers/sent`;
    }
}

export function redirectToMainDomain() {
    const [, ext] = document.location.hostname.match(Constant.regexpDomainExtension);
    window.location.href = document.location.protocol + '//' + Constant.redirection.main + ext + (window.location.port ? ':' + window.location.port : '');
}

// To know if you are on a CNAME or not
export function isAppDomain() {
    return window.location.hostname.match(environment.appDomain) ? true : false;
}
