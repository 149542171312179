import { createAction, props } from '@ngrx/store';
import { GetSubscriptionOutput } from '@smash-sdk/billing/01-2024/types/GetSubscription/GetSubscription';
import { ListCountriesOutput } from '@smash-sdk/vat/10-2019/vat';

export const updateFormData = createAction(
  '[SubscriptionUpdate] Update Form Data',
  props<{ data: any }>()
);

// Load Plans
export const loadPlans = createAction(
  '[SubscriptionUpdate] Load Plans',
);

export const loadPlansSuccess = createAction(
  '[SubscriptionUpdate] Load Plans Success',
  props<{ plans: [GetSubscriptionOutput['subscription']['plan']] }>()
);

export const loadPlansFailure = createAction(
  '[SubscriptionUpdate] Load Plans Failure',
  props<{ error: any }>()
);

export const computePlan = createAction(
  '[SubscriptionUpdate] Compute Plan',
);

export const computePlanSuccess = createAction(
  '[SubscriptionUpdate] Compute Plan Success',
  props<{ computedPlan: any }>()
);

export const computePlanFailure = createAction(
  '[SubscriptionUpdate] Compute Plan Failure',
  props<{ error: any }>()
);

// Load Countries
export const loadCountries = createAction(
  '[SubscriptionUpdate] Load Countries'
);

export const loadCountriesSuccess = createAction(
  '[SubscriptionUpdate] Load Countries Success',
  props<{ countries: ListCountriesOutput['countries'] }>()
);

export const loadCountriesFailure = createAction(
  '[SubscriptionUpdate] Load Countries Failure',
  props<{ error: any }>()
);

// Load VAT
export const loadVAT = createAction(
  '[SubscriptionUpdate] Load VAT'
);

export const loadVATSuccess = createAction(
  '[SubscriptionUpdate] Load VAT Success',
  props<{ VATs: any[] }>()
);

export const loadVATFailure = createAction(
  '[SubscriptionUpdate] Load VAT Failure',
  props<{ error: any }>()
);

// Update Billing Informations
export const updateBillingInformations = createAction(
  '[SubscriptionUpdate] Update Billing Informations'
);

export const updateBillingInformationsSuccess = createAction(
  '[SubscriptionUpdate] Update Billing Informations Success',
);

export const updateBillingInformationsFailure = createAction(
  '[SubscriptionUpdate] Update Billing Informations Failure',
  props<{ error: any }>()
);

//Update Subscription
export const updateSubscription = createAction(
  '[SubscriptionUpdate] Update Subscription',
);

export const updateSubscriptionSuccess = createAction(
  '[SubscriptionUpdate] Update Subscription Success',
);

export const updateSubscriptionFailure = createAction(
  '[SubscriptionUpdate] Update Subscription Failure',
  props<{ error: any }>()
);

export const renewSubscription = createAction(
  '[SubscriptionUpdate] Renew Subscription '
);

export const renewSubscriptionSuccess = createAction(
  '[SubscriptionUpdate] Renew Subscription Success',
);

export const renewSubscriptionFailure = createAction(
  '[SubscriptionUpdate] Renew Subscription Failure',
  props<{ error: string }>()
);

