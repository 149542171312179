import { Background } from '@app/shared/models/background';
import { Customization } from '@app/shared/models/customization';
import { Logo } from '@app/shared/models/logo';
import { Promotion } from '@app/shared/models/promotion';
import { DeliveryType, Notification, PreviewType, AccessTrackingType, TransferConfigurationParameters } from '@app/shared/models/transferConfiguration';
import { createAction, props } from '@ngrx/store';
import { GetEmailChallengeOutput, UpdateEmailChallengeOutput } from '@smash-sdk-private/kyc/11-2023';


export const resetPrepare = createAction('[Prepare] Reset Prepare state');
export const toggleEmailFormExtended = createAction('[Prepare] Toggle Email Form extended');
export const toggleConfigDrawerExpanded = createAction('[Prepare] Toggle Config Drawer');
export const resetDrawer = createAction('[Prepare] Reset Drawer');
export const setUploadFormMode = createAction('[Prepare] Set Upload Form Mode', props<{ uploadFormMode: DeliveryType }>());
export const setConfigFormStatus = createAction('[Prepare] Set configForm status valid/invalid', props<{ configFormStatus: string }>())

export const loadPromotion = createAction('[Prepare] Load  Promotion', props<{ promotion: Promotion }>());
export const loadPromotionSuccess = createAction('[Prepare] Load Promotion Success', props<{ promotion: Promotion }>());
export const loadPromotionFailure = createAction('[Prepare] Load Promotion Failure', props<{ error: string }>());

export const loadExternalPromotions = createAction('[Prepare] Load External Promotions');
export const loadExternalPromotionsSuccess = createAction('[Prepare] Load External Promotions Success', props<{ promotions: Promotion[], language?: string }>());
export const loadExternalPromotionsFailure = createAction('[Prepare] Load External Promotions Failure', props<{ error: string }>());

export const selectRandomExternalPromotion = createAction('[Prepare] Select Random External Promotion', props<{ promotions: [Promotion, ...Promotion[]], language?: string }>());
export const selectRandomExternalPromotionSuccess = createAction('[Prepare] Select Random External Promotion Success', props<{ promotion: Promotion }>());
export const selectRandomExternalPromotionFailure = createAction('[Prepare] Select Random External Promotion Failure', props<{ error: string }>());

export const loadCustomization = createAction('[Prepare] Load Customization');
export const loadCustomizationSuccess = createAction('[Prepare] Load Customization Success', props<{ customization: Customization }>());
export const loadCustomizationFailure = createAction('[Prepare] Load Customization Failure', props<{ error: any }>());


export const loadBackgrounds = createAction('[Prepare] Load Backgrounds');
export const loadBackgroundsSuccess = createAction('[Prepare] Load Backgrounds Success', props<{ backgrounds: Background[] }>());
export const loadBackgroundsFailure = createAction('[Prepare] Load Backgrounds Failure', props<{ error: any }>());

export const loadLogos = createAction('[Prepare] Load Logos');
export const loadLogosSuccess = createAction('[Prepare] Load Logos Success', props<{ logos: Logo[] }>());
export const loadLogosFailure = createAction('[Prepare] Load Logos Failure', props<{ error: any }>());

export const addBackgroundImageToAttach = createAction('[Prepare] Add Background Image To Attach', props<{ image?: any[], url?: string }>());
export const addBackgroundImageToAttachSuccess = createAction('[Prepare] Add Background Image To Attach Success', props<{ background: any }>());
export const addBackgroundImageToAttachFailure = createAction('[Prepare] Add Background Image To Attach Failure', props<{ error: any }>());

export const addBackgroundVideoToAttach = createAction('[Prepare] Add Background Video To Attach', props<{ video: any }>());
export const addBackgroundVideoToAttachSuccess = createAction('[Prepare] Add Background Video To Attach Success', props<{ background: any }>());
export const addBackgroundVideoToAttachFailure = createAction('[Prepare] Add Background Video To Attach Failure', props<{ error: any }>());

export const addLogoToAttach = createAction('[Prepare] Add Team Logo', props<{ image?: any[], url?: string }>());
export const addLogoToAttachSuccess = createAction('[Prepare] Add Team Logo Success', props<{ logo: any }>());
export const addLogoToAttachFailure = createAction('[Prepare] Add Team Logo Failure', props<{ error: any }>());

export const ResetCustomisationEdited = createAction('[Prepare] Reset Customization Edited');
export const saveCustomization = createAction('[Prepare] Save Customization', props<{ logo?: Logo, background?: Background }>());

export const SetTransferConfigurationParameters = createAction('[Prepare] Set Transfer Configuration Parameters', props<{ parameters: TransferConfigurationParameters }>());
export const UpdatePrepareStep = createAction('[Prepare] Set Prepare Step', props<{ step: 'emailSelection' | 'emailVerificationSection' | 'deliveryTypeSelection' | 'parametersSelection' }>());


export const SetTransferAvailability = createAction('[Prepare] Set Transfer Availability', props<{ availability: number }>());
export const SetTransferPassword = createAction('[Prepare] Set Transfer Password', props<{ password: string }>());
export const SetTransferNotification = createAction('[Prepare] Set Transfer Notification', props<{ notification: Notification }>());

export const SetTransferAccessTracking = createAction('[Prepare] Set Transfer AccessTracking', props<{ accessTracking: AccessTrackingType }>());
export const SetTransferPreviewType = createAction('[Prepare] Set Transfer Preview Type', props<{ preview: PreviewType }>());
export const SetTransferCustomization = createAction('[Prepare] Set Transfer Customization', props<{ logo: Logo, background: Background }>());
export const SetTransferPromotion = createAction('[Prepare] Set Transfer Promotion', props<{ promotion: Promotion }>());
export const SetTransferSender = createAction('[Prepare] Set Transfer Sender', props<{ sender: { email: string, name?: string } }>());

export const SetLinkCustomUrl = createAction('[Prepare] Set Link Custom Url', props<{ customUrl: string }>());
export const SetLinkTitle = createAction('[Prepare] Set Link Title', props<{ title: string }>());

export const SetEmailTitle = createAction('[Prepare] Set Email Title', props<{ title: string }>());
export const SetEmailDescription = createAction('[Prepare] Set Email Description', props<{ description: string }>());
export const SetEmailReceivers = createAction('[Prepare] Set Email Receivers', props<{ receivers: string[] }>());

export const DisplayModalSizePremium = createAction('[Prepare] Display Modal Size Premium');
export const HideModalSizePremium = createAction('[Prepare] Hide Modal Size Premium');
export const VerifyEmail = createAction('[Prepare] Verify Email', props<{ username: string }>());
export const VerifyEmailSuccess = createAction('[Prepare] Verify Email Success', props<{ status: GetEmailChallengeOutput['emailChallenge']['status'] }>());
export const VerifyEmailFailure = createAction('[Prepare] Verify Email Failure', props<{ error: any }>());
export const ResetVerifyEmail = createAction('[Prepare] Reset Verify Email');

export const ValidateEmailVerificationCode = createAction('[Prepare] Validate Email Verification Code', props<{ username: string, code: number }>());
export const ValidateEmailVerificationCodeSuccess = createAction('[Prepare] Validate Email Verification Code Success', props<{ status: UpdateEmailChallengeOutput['emailChallenge']['status'] }>());
export const ValidateEmailVerificationCodeFailure = createAction('[Prepare] Validate Email Verification Code Failure', props<{ error: any }>());

export const SendEmailVerificationCode = createAction('[Prepare] Send Email Verification Code', props<{ username: string }>());
export const SendEmailVerificationCodeSuccess = createAction('[Prepare] Send Email Verification Code Success', props<{ status: string }>());
export const SendEmailVerificationCodeFailure = createAction('[Prepare] Send Email Verification Code Failure', props<{ error: any }>());

export const DisplayVerificationCodeForm = createAction('[Prepare] Display Verification Code Form');
export const HideVerificationCodeForm = createAction('[Prepare] Hide Verification Code Form');
