import * as fromUpload from './upload.reducer';
import * as fromPrepare from './prepare.reducer';
import * as fromSend from './send.reducer';
import * as fromWorkflow from './workflow.reducer';
import * as fromLayout from './layout.reducer';
import * as fromDropbox from './dropbox.reducer';

import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

export const uploaderFeatureKey = 'uploader';
export interface UploaderState {
    [fromUpload.uploadFeatureKey]: fromUpload.State;
    [fromPrepare.prepareFeatureKey]: fromPrepare.State;
    [fromSend.sendFeatureKey]: fromSend.State;
    [fromWorkflow.workflowFeatureKey]: fromWorkflow.State;
    [fromLayout.layoutFeatureKey]: fromLayout.State;
    [fromDropbox.dropboxFeatureKey]: fromDropbox.State;
}

export interface State {
    [uploaderFeatureKey]: UploaderState;
}

const uploaderReducer = combineReducers({
    [fromLayout.layoutFeatureKey]: fromLayout.reducer,
    [fromPrepare.prepareFeatureKey]: fromPrepare.reducer,
    [fromSend.sendFeatureKey]: fromSend.reducer,
    [fromUpload.uploadFeatureKey]: fromUpload.reducer,
    [fromWorkflow.workflowFeatureKey]: fromWorkflow.reducer,
    [fromDropbox.dropboxFeatureKey]: fromDropbox.reducer,
});

export function reducers(state: UploaderState | undefined, action: Action) {
    return uploaderReducer(state, action);
}

export const getUploaderState = createFeatureSelector<UploaderState>(uploaderFeatureKey);


export const getLayoutState = createSelector(getUploaderState, state => state.layout);
export const getPrepareState = createSelector(getUploaderState, state => state.prepare);
export const getSendState = createSelector(getUploaderState, state => state.send);
export const getUploadState = createSelector(getUploaderState, state => state.upload);
export const getWorkflowState = createSelector(getUploaderState, state => state.workflow);
export const getDropboxState = createSelector(getUploaderState, state => state.dropbox);
export const getPrepareStep = createSelector(getPrepareState, fromPrepare.getPrepareStep);

// Layout selectors
export const getMenuDisplayed = createSelector(getLayoutState, fromLayout.getMenuDisplayed);
export const getIsFileOver = createSelector(getLayoutState, fromLayout.getIsFileOver);

// Prepare selectors
export const getConfigDrawerExpanded = createSelector(getPrepareState, fromPrepare.getConfigDrawerExpanded);
export const getExternalPromotions = createSelector(getPrepareState, fromPrepare.getExternalPromotions);
export const getExternalPromotionsLoaded = createSelector(getPrepareState, fromPrepare.getExternalPromotionsLoaded);
export const getExternalPromotionSelected = createSelector(getPrepareState, fromPrepare.getExternalPromotionSelected);
export const getExternalPromotionsLoading = createSelector(getPrepareState, fromPrepare.getExternalPromotionsLoading);
export const getExternalPromotionsError = createSelector(getPrepareState, fromPrepare.getExternalPromotionsError);

export const getCustomization = createSelector(getPrepareState, fromPrepare.getCustomization);
export const getCustomizationLoading = createSelector(getPrepareState, fromPrepare.getCustomizationLoading);
export const getCustomizationLoaded = createSelector(getPrepareState, fromPrepare.getCustomizationLoaded);
export const getCustomizationError = createSelector(getPrepareState, fromPrepare.getCustomizationError);
export const getCustomizationLogos = createSelector(getPrepareState, fromPrepare.getLogos);
export const getCustomizationLogosLoading = createSelector(getPrepareState, fromPrepare.getCustomizationLogosLoading);
export const getCustomizationLogosLoaded = createSelector(getPrepareState, fromPrepare.getCustomizationLogosLoaded);
export const getCustomizationBackgrounds = createSelector(getPrepareState, fromPrepare.getCustomizationBackgrounds);
export const getCustomizationBackgroundsLoading = createSelector(getPrepareState, fromPrepare.getCustomizationBackgroundsLoading);
export const getCustomizationBackgroundsLoaded = createSelector(getPrepareState, fromPrepare.getCustomizationBackgroundsLoaded);
export const getCustomizationEdited = createSelector(getPrepareState, fromPrepare.getCustomizationEdited);
export const getCustomizationMediaLoading = createSelector(getPrepareState, fromPrepare.getCustomizationMediaLoading);

export const getUploadFormMode = createSelector(getPrepareState, fromPrepare.getUploadFormMode);
export const getConfigFormStatus = createSelector(getPrepareState, fromPrepare.getConfigFormStatus);

export const getTransferCommonParameters = createSelector(getPrepareState, fromPrepare.getTransferCommonParameters);
export const getTransferEmailParameters = createSelector(getPrepareState, fromPrepare.getTransferEmailParameters);
export const getTransferLinkParameters = createSelector(getPrepareState, fromPrepare.getTransferLinkParameters);
export const getTransferSize = createSelector(getPrepareState, fromPrepare.getTransferSize);
export const getTransferPassword = createSelector(getPrepareState, fromPrepare.getTransferPassword);
export const getTransferCustomization = createSelector(getPrepareState, fromPrepare.getTransferCustomization);
export const getTransferPromotion = createSelector(getPrepareState, fromPrepare.getTransferPromotion);
export const getTransferAvailabilityDuration = createSelector(getPrepareState, fromPrepare.getTransferAvailabilityDuration);
export const getTransferPreview = createSelector(getPrepareState, fromPrepare.getTransferPreview);
export const getTransferNotification = createSelector(getPrepareState, fromPrepare.getTransferNotification);
export const getTransferSender = createSelector(getPrepareState, fromPrepare.getTransferSender);

export const getModalSizePremiumDisplayed = createSelector(getPrepareState, fromPrepare.getModalSizePremiumDisplayed);

export const getVerifyEmailResponse = createSelector(getPrepareState, fromPrepare.getVerifyEmailResponse);
export const getVerifyEmailLoaded = createSelector(getPrepareState, fromPrepare.getVerifyEmailLoaded);
export const getVerifyEmailLoading = createSelector(getPrepareState, fromPrepare.getVerifyEmailLoading);
export const getVerifyEmailError = createSelector(getPrepareState, fromPrepare.getVerifyEmailError);

export const getValidateEmailVerificationCodeResponse = createSelector(getPrepareState, fromPrepare.getValidateEmailVerificationCodeResponse);
export const getValidateEmailVerificationCodeLoaded = createSelector(getPrepareState, fromPrepare.getValidateEmailVerificationCodeLoaded);
export const getValidateEmailVerificationCodeLoading = createSelector(getPrepareState, fromPrepare.getValidateEmailVerificationCodeLoading);
export const getValidateEmailVerificationCodeError = createSelector(getPrepareState, fromPrepare.getValidateEmailVerificationCodeError);

export const getSendEmailVerificationLoading = createSelector(getPrepareState, fromPrepare.getSendEmailVerificationLoading);
export const getSendEmailVerificationLoaded = createSelector(getPrepareState, fromPrepare.getSendEmailVerificationLoaded);
export const getSendEmailVerificationError = createSelector(getPrepareState, fromPrepare.getSendEmailVerificationError);

export const getVerificationCodeFormDisplayed = createSelector(getPrepareState, fromPrepare.getVerificationCodeFormDisplayed);

// Upload selectors
export const getTransferStartDate = createSelector(getUploadState, fromUpload.getTransferStartDate);
export const getUploadError = createSelector(getUploadState, fromUpload.getUploadError);
export const getTransfer = createSelector(getUploadState, fromUpload.getTransfer);

export const getTransferQueue = createSelector(getUploadState, fromUpload.getTransferQueue);

export const getUploadStatus = createSelector(getUploadState, fromUpload.getUploadStatus);
export const getUploadProgress = createSelector(getUploadState, fromUpload.getUploadProgress);
export const getUploadedBytes = createSelector(getUploadState, fromUpload.getUploadedBytes);
export const getRemainingSize = createSelector(getUploadState, fromUpload.getRemainingSize);
export const getUploadSpeed = createSelector(getUploadState, fromUpload.getUploadSpeed);

export const getRawFilesState = createSelector(getUploadState, state => state.filesState.rawFilesState);
export const getRawFilesEntities = createSelector(getRawFilesState, fromUpload.selectEntitiesRawFiles);
export const getAllRawFiles = createSelector(getRawFilesState, fromUpload.selectAllRawFiles);

export const getRawFilesError = createSelector(getUploadState, fromUpload.getRawFilesError);

export const getUpdateAndResumeUploadLoading = createSelector(getUploadState, fromUpload.getUpdateAndResumeUploadLoading);

// Send
export const getPromotionLoaded = createSelector(getSendState, fromSend.getPromotionLoaded);
export const getPromotionLoading = createSelector(getSendState, fromSend.getPromotionLoading);

// Workflow state
export const getStep = createSelector(getWorkflowState, fromWorkflow.getStep);
export const getMode = createSelector(getWorkflowState, fromWorkflow.getMode);

// Dropbox state
export const getDropbox = createSelector(getDropboxState, fromDropbox.getDropbox);
export const getDropboxError = createSelector(getDropboxState, fromDropbox.getDropboxError);
export const getDropboxCreating = createSelector(getDropboxState, fromDropbox.getDropboxCreating);
export const getDropboxCreated = createSelector(getDropboxState, fromDropbox.getDropboxCreated);

export const getDropboxPreview = createSelector(getDropboxState, fromDropbox.getDropboxPreview);
export const getDropboxPreviewLoading = createSelector(getDropboxState, fromDropbox.getDropboxPreviewLoading);
export const getDropboxPreviewLoaded = createSelector(getDropboxState, fromDropbox.getDropboxPreviewLoaded);
export const getCanConfigureDropboxTransfer = createSelector(getDropboxState, fromDropbox.getCanConfigDropboxTransfer);
