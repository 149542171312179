import { Action, createReducer, on } from '@ngrx/store';
import { LayoutActions } from '../actions';


export const layoutFeatureKey = 'layout';

export interface State {
  menuDisplayed: boolean;
  fileOver: boolean;
}

export const initialState: State = {
  menuDisplayed: false,
  fileOver: false,
};

const layoutReducer = createReducer(
  initialState,
  on(LayoutActions.ToggleMenu, (state, { menuDisplayed }) => ({ ...state, menuDisplayed })),
  on(LayoutActions.UpdateFileOver, (state, { fileOver }) => ({ ...state, fileOver })),
);

export function reducer(state: State | undefined, action: Action) {
  return layoutReducer(state, action);
}

export const getMenuDisplayed = (state: State) => state.menuDisplayed;
export const getIsFileOver = (state: State) => state.fileOver;
